import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider } from '@praxis/component-auth'
import { EnvProvider } from '@praxis/component-runtime-env'
import { useState } from 'react'
import { CookiesProvider } from 'react-cookie'

import apiConfig from './config/apiConfig'
import commonConfig from './config/commonConfig'
import { BreadcrumbContext } from './context/Context'
import PopLayout from './globalComponents/PopLayout'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'
import './scss/App.scss'

document.body.style.backgroundColor = '#EFEFEF'

const App = () => {
  const [breadcrumbContext, setBreadcrumbContext] = useState({})

  return process.env.NODE_ENV ? (
    <ToastProvider location="bottom">
      <EnvProvider
        commonConfig={commonConfig}
        configPath={
          process.env.NODE_ENV === 'development'
            ? '/config.json'
            : '/app-environment'
        }
      >
        {({ env }) => (
          <AuthProvider
            {...env.auth}
            authorizationUrl={apiConfig.auth.authorizationUrl}
            clientId={apiConfig.auth.clientId}
            logoutUrl={apiConfig.auth.logoutUrl}
            loginRedirect={apiConfig.auth.loginRedirect}
          >
            <CookiesProvider>
              <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
              <BreadcrumbContext.Provider
                value={{ breadcrumbContext, setBreadcrumbContext }}
              >
                <PopLayout />
              </BreadcrumbContext.Provider>
            </CookiesProvider>
          </AuthProvider>
        )}
      </EnvProvider>
    </ToastProvider>
  ) : null
}
export default App
