import {
  Button,
  Form,
  GridContainer,
  Grid,
  ProgressBar,
  Tooltip,
  ToastProvider,
  GridItem,
} from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import {
  faCopy,
  faPalette,
  faPlus,
  faMinus,
  faTrashAlt,
  faKey,
  faExclamationTriangle,
  faClipboard,
  faTimesCircle,
  faChevronDown,
  faChevronRight,
  faClose,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash.isempty'
import { useContext, useMemo, useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import DragHandle from '../../../src/components/DragHandle'
import OfferApi from '../../api/creative-hub/OfferApi'
import appConfig from '../../config/appConfig'
import { MerchantViewContext } from '../../context/Context'
import { IvyChannels } from '../../creativeHubGlobal/util/CreativeHubGlobalUtil'
import { getDivisionName } from '../../creativeHubGlobal/util/MerchandiseUtil'
import {
  canEnableSendEmailButton,
  getOnlineOnlyOffers,
  copyOfferLink,
  checkOfferDatesAgainstProjectDates,
  isDisableEmailNotifyButton,
  checkForAllMarkets,
  isOfferAddedAfterProjectKODate,
  makeOfferDetails,
} from '../../creativeHubGlobal/util/OfferUtil'
import InlineEditInput from '../../planning/components/InlineEditInput'
import { NOTIFICATION } from '../../util/ApiConstants'
import { formatToUi } from '../../util/MomentUtil'

import '../scss/Offer.scss'
import ExpandableOfferComment from './ExpandableOfferComment'
import ItemBucket from './ItemBucket'

const Offer = (props) => {
  const {
    canEdit,
    offer = {},
    umbrella,
    storyId,
    outcomePriorityId,
    followers,
    setEditOfferArtPatch,
    handleDeleteOffer,
    setIsOfferDrawerOpen,
    setSelectedOffer,
    isFollower,
    recentlyClickedOffer,
    setRecentlyClickedOffer = () => {},
    projectStartDate,
    projectEndDate,
    setSelectedUmbrellaId,
    removeOffer,
    displayDivisions = [],
    divisions = {},
    projectId,
    setIsItemImageModalOpen,
    setItemSelected,
    updatedItemImage,
    selectedStoryId,
  } = props
  const {
    ad_patches = [],
    mixed_discount = false,
    copy = '',
    created_date,
    deleted = false,
    division = '',
    end_date,
    invalid_dates = false,
    id,
    ivy_channel = '',
    ivy_offer_id = -1,
    merch_notes = '',
    project_id = '',
    name = '',
    offer_order,
    is_key = false,
    sale_handle = '',
    start_date,
    status,
    price_patches = [],
    total_item_count = 0,
    featured_item_count = 0,
  } = offer

  //  query params used when user landing through email links
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const canExpandOffer = query.get('canExpandOffer')

  const { project = {} } = useContext(MerchantViewContext)
  const config = appConfig()
  const isPlaceholderOffer = ivy_offer_id === -1
  const isDeactivated = status === 'deactivated' || deleted
  const divisionName = deleted ? '' : getDivisionName(divisions, division)
  const [offerForm, setOfferForm] = useState(offer)
  const [offerShowMore, setOfferShowMore] = useState(true)
  const [, setUpdatedOffers] = useState()
  const [offerShowFeaturedItems, setOfferShowFeaturedItems] = useState(true)
  const [expandOffer, setExpandOffer] = useState(
    recentlyClickedOffer === offer.id ? true : false
  )
  const [saleHandleDropdown, setSaleHandleDropdown] = useState(sale_handle)
  const [offerCopy, setOfferCopy] = useState(copy)

  const [changesMade, setChangesMade] = useState(false)
  const [offerMerchNotes, setOfferMerchNotes] = useState(merch_notes)
  const [selectedDivision, setSelectedDivision] = useState(division)
  const [loading, setLoading] = useState(false)

  const setToaster = ToastProvider.useToaster()

  const handleCopyChange = (input) => setOfferCopy(input)
  const handleMerchNotesChange = (input) => setOfferMerchNotes(input)

  const offerApi = useMemo(() => new OfferApi(), [])
  const offerDatesDiffFromProjectDates = checkOfferDatesAgainstProjectDates(
    offer.start_date,
    offer.end_date,
    projectStartDate,
    projectEndDate
  )

  const [ivyChannelDisplay, setIvyChannelDisplay] = useState(
    IvyChannels[ivy_channel]
  )
  const inPublishingView = useLocation().pathname?.includes('publishing')

  const [height, setHeight] = useState()
  const elementRef = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      setHeight(elementRef?.current?.offsetHeight)
    }
    handleResize()
    window.addEventListener('click', handleResize)

    return () => {
      window.removeEventListener('click', handleResize)
    }
  }, [])

  const showPicturedItems = (offer) => {
    setSelectedOffer(offer)
    setRecentlyClickedOffer(offer?.id)
    setIsOfferDrawerOpen(true)
    setOfferShowFeaturedItems(!offerShowFeaturedItems)
    if (!expandOffer) {
      setExpandOffer(true)
      setOfferShowFeaturedItems(true)
      setOfferShowMore(true)
    }
  }

  const copyOfferLinkOnclick = (e) => {
    e.stopPropagation()
    copyOfferLink({ ivy_offer_id, name })
  }

  const updateValue = async (data = {}, field, value) => {
    if (data[field] !== value) {
      data[field] = value
      //update data
      try {
        const resp = await offerApi.updateOffers([data])
        return resp
      } catch (error) {
        console.log(error)
        return null
      }
    }
  }

  const deleteOffer = () => {
    handleSendOfferEmail(NOTIFICATION['DELETE_PLACEHOLDER_OFFER'])
    handleDeleteOffer(offer.id)
  }

  const override = (event) => {
    //   This is required to avoid opening and closing
    //   the Expandable section when editing values
    event.stopPropagation()
  }

  const handleSendOfferEmail = (notificationType) => {
    const payload = {
      ivy_offer_id: ivy_offer_id,
      ivy_umbrella_id: umbrella.ivy_umbrella_id,
      notification_type: notificationType,
      offer_id: id,
      offer_name: name,
      project_id: projectId,
      story_id: storyId,
      umbrella_id: umbrella.planning_umbrella_id,
      umbrella_name: umbrella.name,
    }
    offerApi
      .sendOfferEmail(payload)
      .then((response) => {
        setUpdatedOffers([])
        setToaster({
          color: 'success',
          headingText: 'Success',
          isVisible: true,
          message: response,
        })
      })
      .catch((e) => {
        console.log('error', e)
        setToaster({
          color: 'error',
          headingText: 'Error',
          isVisible: true,
          message: `error, ${e} `,
        })
      })
  }

  useEffect(() => {
    if (recentlyClickedOffer === id) {
      offerApi
        .verifyEnableOfferNotification(projectId, ivy_offer_id, id)
        .then((response) => {
          setChangesMade(response.is_offer_notify_enabled)
        })
        .catch((e) => {
          console.log('error', e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (expandOffer === true) {
      setLoading(true)

      try {
        const be = offerApi
          .getOffer(offer.id)
          .then((data) => {
            setOfferForm({ ...offer, ...data, channels: offer.channels })
            handleMerchNotesChange(data.merch_notes)
            handleCopyChange(data.copy)
            setSaleHandleDropdown(data.sale_handle)
            setIvyChannelDisplay(IvyChannels[data.ivy_channel])
            return data
          })
          .catch((e) => {
            console.log('error', e)
          })
          .finally(() => {
            setLoading(false)
          })

        setOfferForm(be)
      } catch (error) {
        console.log(error)
        return undefined
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandOffer])

  return (
    <GridContainer
      data-testid="offer"
      spacing="none"
      align="center"
      xs={12}
      className="offer"
    >
      {' '}
      <Grid.Item xs={0.5} className="drag-handle">
        <DragHandle />
      </Grid.Item>
      <Grid.Item
        xs={0.5}
        align="center"
        style={{ cursor: 'pointer' }}
        data-testid="offer-expand"
        onClick={(e) => {
          e.stopPropagation()
          if (!inPublishingView) {
            setSelectedUmbrellaId(umbrella?.planning_umbrella_id)
            if (expandOffer === false) {
              setRecentlyClickedOffer(offer?.id)
              setSelectedOffer(offerForm)
              setOfferShowFeaturedItems(true)
              setOfferShowMore(true)
            } else {
              setRecentlyClickedOffer(null)
            }
          }
          setExpandOffer(!expandOffer)
        }}
      >
        {' '}
        <FontAwesomeIcon icon={expandOffer ? faChevronDown : faChevronRight} />
      </Grid.Item>
      <Grid.Item
        xs={1}
        align="left"
        className={`headerRow${
          isDeactivated ? ' deactivated-offer-header' : ''
        } ${deleted ? 'invalidDates' : ''}`}
        data-cy={`offer${offer_order}`}
      >
        {isPlaceholderOffer ? (
          <span title="Placeholder Offer">
            <FontAwesomeIcon
              icon={faClipboard}
              className="fa-duotone "
              size="lg"
              color="#aaaaaa"
            />
          </span>
        ) : invalid_dates ? (
          <span
            title="This offer is no longer associated with this umbrella.  Please check Ivy for more details"
            className="deactivated-offer-header"
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="fa-duotone "
              size="lg"
              color="#aaaaaa"
            />
          </span>
        ) : deleted ? (
          <span title="This offer was removed from this umbrella.  Please check Ivy for more details">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="fa-duotone "
              size="lg"
              color="#aaaaaa"
            />
          </span>
        ) : isDeactivated ? (
          'Deactivated'
        ) : isFollower ? (
          ''
        ) : isOfferAddedAfterProjectKODate(
            project.offers_due_date,
            created_date
          ) ? (
          <span
            title={`Added to CH ${formatToUi(created_date)}`}
            className="late_offer_background"
          >
            {`Offer ${offer_order}`}
          </span>
        ) : (
          `Offer ${offer_order}`
        )}
      </Grid.Item>
      <Grid.Item
        xs={4}
        align="left"
        className={`headerRow${
          isDeactivated ? ' deactivated-offer-header' : ''
        }`}
      >
        {isPlaceholderOffer && canEdit ? (
          <InlineEditInput
            data={offer}
            field="name"
            value={name || ''}
            multiline={false}
            placeholder="enter placeholder offer name"
          />
        ) : (
          <div className="offerName">
            <a
              target="_blank"
              href={`${config.ivy.uiUrl}promotion/${ivy_offer_id}/edit/general`}
              rel="noreferrer"
              draggable={false}
              className={`${
                isDeactivated ? 'deactivated-offer-header' : 'offerLink'
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {is_key ? (
                <FontAwesomeIcon
                  icon={faKey}
                  className="fa-duotone key"
                  size="lg"
                  color="black"
                />
              ) : null}
              {deleted && ivy_offer_id && name === '' ? ivy_offer_id : name}
            </a>
            {isDeactivated ? null : (
              <FontAwesomeIcon
                className="copy-btn"
                icon={faCopy}
                onClick={copyOfferLinkOnclick}
              />
            )}
          </div>
        )}
      </Grid.Item>
      {!isPlaceholderOffer ? (
        <Grid.Item xs={3}>
          <GridContainer spacing="none" align="center" xs={12}>
            <Grid.Item
              xs={6}
              align="left"
              className="headerRow headerButtonText"
            >
              {featured_item_count > 0 ? (
                <Button
                  size="dense"
                  onClick={() => showPicturedItems(offerForm)}
                  className={`${
                    isDeactivated
                      ? ' deactivated-offer'
                      : ad_patches && ad_patches.length > 1
                      ? ' adPatch-offer'
                      : !mixed_discount &&
                        price_patches &&
                        price_patches.length > 1
                      ? ' pricePatch-offer'
                      : ''
                  }`}
                >
                  <p
                    className={`${
                      isDeactivated
                        ? 'deactivated-offer-header'
                        : 'headerButtonText'
                    }`}
                  >
                    {ad_patches && ad_patches.length > 1
                      ? `Patches (${featured_item_count})`
                      : `Featured (${featured_item_count})`}
                  </p>
                </Button>
              ) : null}
            </Grid.Item>
            <Grid.Item
              xs={6}
              align="left"
              className="headerRow headerButtonText"
            >
              {total_item_count ? (
                <p
                  className={`ivyTotalItems${
                    isDeactivated ? ' deactivated-offer-header' : ''
                  }`}
                >
                  Total Items ({total_item_count})
                </p>
              ) : null}
            </Grid.Item>
          </GridContainer>
        </Grid.Item>
      ) : null}
      <Grid.Item
        xs={2}
        className={offerDatesDiffFromProjectDates ? 'boldDates' : ''}
        style={{ paddingRight: 5 }}
      >
        {isPlaceholderOffer || deleted ? (
          ''
        ) : (
          <>
            {start_date ? (
              <DateFormatter
                date={start_date}
                format="MM/DD"
                timezone="America/Los_Angeles"
              />
            ) : (
              ''
            )}
            {' - '}
            {end_date ? (
              <DateFormatter
                date={end_date}
                format="MM/DD"
                timezone="America/Los_Angeles"
              />
            ) : (
              ''
            )}
          </>
        )}

        {!isEmpty(followers) && (
          <Tooltip location="top" content="Edit Art Patches">
            <Button
              aria-label="edit art patches"
              iconOnly
              onClick={(event) => {
                event.stopPropagation()
                setEditOfferArtPatch({
                  body: {
                    followers: followers,
                    leader: offer,
                    umbrella,
                  },
                  show: true,
                })
              }}
            >
              <FontAwesomeIcon icon={faPalette} color={'#000000'} />
            </Button>
          </Tooltip>
        )}
      </Grid.Item>
      {isPlaceholderOffer && canEdit ? (
        <Grid.Item xs={3} align="left" className="headerRow">
          <Form.Field
            type="select"
            id="division"
            onUpdate={(id, value) => {
              setSelectedDivision(value)
              updateValue(offer, id, value)
            }}
            options={displayDivisions}
            value={selectedDivision || ''}
            defaultValue=""
            onBlur={override}
            onChange={override}
            onClick={override}
          />
        </Grid.Item>
      ) : (
        <>
          {!deleted ? (
            <Grid.Item
              xs={1}
              align="left"
              className={`headerRow${
                isDeactivated ? ' deactivated-offer-header' : ''
              }`}
            >
              {divisionName}
            </Grid.Item>
          ) : null}
        </>
      )}
      <Grid.Item xs={1} align="center" className="headerRow">
        {inPublishingView ? (
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={(e) => {
              removeOffer(e, offer.id)
            }}
          />
        ) : (
          <>
            {(isPlaceholderOffer || deleted) && canEdit ? (
              <FontAwesomeIcon icon={faTrashAlt} onClick={deleteOffer} />
            ) : null}
          </>
        )}
      </Grid.Item>
      {/* put into own component and pass offerForm, expandOffer as prop*/}
      {offerForm &&
      ((expandOffer && !loading) ||
        (isFollower === true &&
          recentlyClickedOffer === offer.offer_art_patch_leader_id)) ? (
        <Grid.Container
          xs={12}
          className="expanded-offer"
          justify="space-between"
          spacing="normal"
        >
          <Grid.Item
            xs={offerShowFeaturedItems ? 7 : 12}
            className="offer-info"
            ref={elementRef}
          >
            <GridContainer align="center" xs={12}>
              <Grid.Item className="hc-bg-grey05" xs={4}>
                <h4>
                  Sale Handle{' '}
                  {canEdit ? (
                    <span class="C-FormField__Label__Required">*</span>
                  ) : (
                    <span></span>
                  )}
                </h4>
                {canEdit ? (
                  <span class="C-FormField__Label__saleHandleRequired">
                    Required Field
                  </span>
                ) : (
                  <span></span>
                )}
              </Grid.Item>
              <Grid.Item className="user-field" xs={8}>
                <Form.Field
                  id="sale_handle"
                  type="select"
                  disabled={!canEdit || isDeactivated}
                  value={saleHandleDropdown}
                  options={[
                    {
                      label: '',
                      value: '',
                    },
                    {
                      label: 'Yes',
                      value: 'Yes',
                    },
                    {
                      label: 'No',
                      value: 'No',
                    },
                  ]}
                  onUpdate={(id, value) => {
                    setSaleHandleDropdown(value)
                    updateValue(offerForm, id, value)
                    setChangesMade(true)
                  }}
                />
              </Grid.Item>
            </GridContainer>
            <GridContainer align="center" xs={12}>
              <Grid.Item className="hc-bg-grey05" xs={4}>
                <h4>Offer Details</h4>
              </Grid.Item>
              <Grid.Item xs={8} className="user-field">
                {makeOfferDetails(offerForm.circle_offer, offerForm.mass_offer)}
              </Grid.Item>
            </GridContainer>
            <GridContainer align="center" xs={12}>
              <Grid.Item className="hc-bg-grey05" xs={4}>
                <h4>Promo $</h4>
              </Grid.Item>
              <Grid.Item className="user-field" xs={8}>
                {offerForm.mixed_discount
                  ? 'See featured items for promo $ values'
                  : offerForm.promo_content === '0'
                  ? 'See featured items for online Reg $ values'
                  : offerForm.promo_content}
              </Grid.Item>
            </GridContainer>
            <GridContainer align="center" xs={12}>
              <Grid.Item className="hc-bg-grey05" xs={4}>
                <h4>Discount Type</h4>
              </Grid.Item>
              <Grid.Item xs={8} className="user-field">
                {offerForm.discount_type}
              </Grid.Item>
            </GridContainer>
            <GridContainer align="center" xs={12}>
              <Grid.Item className="hc-bg-grey05" xs={4}>
                <h4>Copy Direction</h4>
              </Grid.Item>
              <Grid.Item className="user-field" xs={8}>
                <InlineEditInput
                  data={offerForm}
                  field="copy"
                  value={offerCopy}
                  setChangesMade={setChangesMade}
                  handleCopyChange={handleCopyChange}
                  multiline={true}
                  placeholder=""
                  data-testid="copyField"
                  isDeactivated={isDeactivated || !canEdit}
                />{' '}
              </Grid.Item>
            </GridContainer>
            <GridContainer align="center" xs={12}>
              <Grid.Item className="hc-bg-grey05" xs={4}>
                <h4>Merch Notes</h4>
              </Grid.Item>
              <Grid.Item className="user-field" xs={8}>
                <Form.Field
                  type="textarea"
                  value={offerMerchNotes || ''}
                  onChange={(e) => {
                    setOfferMerchNotes(e.target.value)
                    setChangesMade(true)
                  }}
                  multiline={true}
                  placeholder={''}
                  disabled={(isDeactivated && deleted) || !canEdit}
                />
              </Grid.Item>
            </GridContainer>
            <GridContainer align="center">
              <Grid.Item className="hc-bg-grey05" xs={4}>
                <h4>Offer Comments</h4>
              </Grid.Item>
              <Grid.Item className="user-field" xs={8}>
                <ExpandableOfferComment
                  offer={offerForm}
                  projectId={project_id}
                  umbrella={umbrella}
                  outcomePriorityId={outcomePriorityId}
                  storyId={storyId}
                  canEdit={canEdit}
                  canExpandOffer={canExpandOffer}
                />
              </Grid.Item>
            </GridContainer>
            <GridContainer
              className={`expand-offer${
                offerShowMore ? '_showMore' : '_showLess'
              }`}
              justify="space-between"
            >
              <Grid.Item className="hc-bg-contrast-weak06 offerExpand">
                {canEnableSendEmailButton(
                  project.offers_due_date,
                  end_date,
                  isPlaceholderOffer
                ) ? (
                  <Button
                    data-testid="notifyEmailbutton"
                    xs={2}
                    className={`emailButton${
                      offerShowMore ? '_showMore' : '_showLess'
                    }`}
                    type="secondary"
                    disabled={isDisableEmailNotifyButton(
                      offerForm.sale_handle,
                      changesMade
                    )}
                    onClick={(event) => {
                      event.stopPropagation()
                      handleSendOfferEmail(NOTIFICATION['REVISED_OFFER'])
                      setChangesMade(false)
                      updateValue(offerForm, 'merch_notes', offerMerchNotes)
                    }}
                  >
                    {' '}
                    Notify Mktg of New Inputs
                  </Button>
                ) : null}
              </Grid.Item>
              <GridItem>
                <Button
                  xs={2}
                  className="expandButton"
                  onClick={() => setOfferShowMore(!offerShowMore)}
                  data-testid="expandButton"
                >
                  {offerShowMore ? (
                    <>
                      <FontAwesomeIcon icon={faMinus} />
                      <span>Show Less</span>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faPlus} />
                      <span>Show More</span>
                    </>
                  )}
                </Button>
              </GridItem>
            </GridContainer>

            {offerShowMore ? (
              <>
                <GridContainer align="center" xs={12}>
                  {' '}
                  <Grid.Item className="hc-bg-grey05" xs={4}>
                    <h4>Offer Id</h4>
                  </Grid.Item>
                  <Grid.Item className="user-field" xs={8}>
                    {isPlaceholderOffer ? 'N/A' : ivy_offer_id}
                  </Grid.Item>{' '}
                </GridContainer>

                <GridContainer align="center" xs={12}>
                  <Grid.Item className="hc-bg-grey05" xs={4}>
                    <h4>Offer Status</h4>
                  </Grid.Item>
                  <Grid.Item className="user-field" xs={8}>
                    {offerForm.status}
                  </Grid.Item>
                </GridContainer>

                <GridContainer align="center" xs={12}>
                  <Grid.Item className="hc-bg-grey05" xs={4}>
                    <h4>AB Email</h4>
                  </Grid.Item>
                  <Grid.Item className="user-field" xs={8}>
                    {offerForm.ab_email}
                  </Grid.Item>
                </GridContainer>

                <GridContainer align="center" xs={12}>
                  <Grid.Item className="hc-bg-grey05" xs={4}>
                    <h4>Markets</h4>
                  </Grid.Item>
                  <Grid.Item className="user-field" xs={8}>
                    {checkForAllMarkets(
                      offerForm.markets,
                      offerForm.location_group_id,
                      offerForm?.price_patches,
                      offerForm.mixed_discount
                    )}
                  </Grid.Item>
                </GridContainer>

                <GridContainer align="center" xs={12}>
                  <Grid.Item className="hc-bg-grey05" xs={4}>
                    <h4>Selling Channel</h4>
                  </Grid.Item>
                  <Grid.Item className="user-field" xs={8}>
                    {ivyChannelDisplay}
                  </Grid.Item>
                </GridContainer>

                <GridContainer align="center" xs={12}>
                  <Grid.Item className="hc-bg-grey05" xs={4}>
                    <h4>Online-Only Offers</h4>
                  </Grid.Item>
                  <Grid.Item className="user-field" xs={8}>
                    {getOnlineOnlyOffers(offerForm.variable_profitability)}
                  </Grid.Item>
                </GridContainer>

                {!offerForm.mixed_discount &&
                offerForm?.price_patches?.length > 0 ? (
                  <>
                    <Grid.Item
                      className="hc-bg-grey05 pricePatch"
                      xs={12}
                      data-testid="price-patch-header"
                    >
                      <h4>Price Patching</h4>
                    </Grid.Item>
                    {offerForm.price_patches.map((patch = {}, index) => (
                      <>
                        {patch.price_markets?.length > 0 ? (
                          <GridContainer
                            testid="offer_price_markets"
                            spacing="none"
                            align="center"
                            xs={12}
                            key={'patch-' + index}
                          >
                            <Grid.Item className="hc-bg-grey05" xs={3}>
                              <h4>Price</h4>
                            </Grid.Item>
                            <Grid.Item className="user-field" xs={3}>
                              {patch.price_patch}
                            </Grid.Item>
                            <Grid.Item className="hc-bg-grey05" xs={3}>
                              <h4>Markets</h4>
                            </Grid.Item>
                            <Grid.Item className="user-field" xs={3}>
                              {patch.price_markets.join(', ')}
                            </Grid.Item>
                          </GridContainer>
                        ) : null}
                      </>
                    ))}
                  </>
                ) : null}
              </>
            ) : null}
          </Grid.Item>

          {offerShowFeaturedItems ? (
            <Grid.Item
              xs={4}
              className="item-bucket-container"
              style={{ height: `${height}px` }}
            >
              <GridContainer align="center" xs={12} justify="space-between">
                <GridItem className="featured-items-header" xs={8}>
                  Featured Items
                </GridItem>
                <GridItem
                  style={{ cursor: 'pointer' }}
                  xs={2}
                  onClick={(e) => {
                    setOfferShowFeaturedItems(false)
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faClose} color="#000000" />
                </GridItem>

                <GridItem xs={12}>
                  <ItemBucket
                    selectedOffer={offerForm}
                    setIsItemImageModalOpen={setIsItemImageModalOpen}
                    setItemSelected={setItemSelected}
                    updatedItemImage={updatedItemImage}
                    selectedStoryId={selectedStoryId}
                    setUpdatedOffers={setUpdatedOffers}
                    isDeactivated={isDeactivated}
                    setChangesMade={setChangesMade}
                  />
                </GridItem>
              </GridContainer>
            </Grid.Item>
          ) : null}
        </Grid.Container>
      ) : loading ? (
        <Grid.Container>
          <Grid.Item className="hc-pt-expanded" xs={12}>
            <ProgressBar indeterminate />
          </Grid.Item>
        </Grid.Container>
      ) : null}
    </GridContainer>
  )
}

export default Offer
